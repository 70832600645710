<template>
  <b-sidebar id="edit-result-sidebar" :visible="isEditResultSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-result-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Result
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <b-row>
            <!-- <b-col md="3" /> -->
            <b-col md="3">
              <!-- Result discussion -->
              <validation-provider #default="validationContext" name="Discussion" rules="required">
                <b-form-group label="Discussion*" label-for="discussion">
                  <!-- <b-form-input id="discussion" v-model="resultData.discussion" :state="getValidationState(validationContext)"
                trim /> -->
                  <b-form-checkbox v-model="resultData.discussion" class="custom-control-success" switch>
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <!-- <b-col md="3" /> -->
            <b-col md="6">
              <!-- Result proposedDecision -->
              <validation-provider #default="validationContext" name="Proposed Decision" rules="required">
                <b-form-group label="Proposed Decision*" label-for="proposedDecision">
                  <!-- <b-form-input id="proposedDecision" v-model="resultData.proposedDecision"
                :state="getValidationState(validationContext)" trim /> -->
                  <v-select v-model="resultData.proposedDecision" :options="proposedDecisionOptions"
                    placeholder="Select a Decision" class="w-100" :reduce="val => val.value" :clearable="false" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Comments -->
          <validation-provider #default="validationContext" name="Comments">
            <b-form-group label="Comments" label-for="comments">
              <b-form-textarea id="comments" v-model="resultData.comments"
                :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Save
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditResultSidebarActive',
    event: 'update:is-edit-result-sidebar-active',
  },
  props: {
    isEditResultSidebarActive: {
      type: Boolean,
      required: true,
    },
    proposedDecisionOptions: {
      type: Array,
      required: true,
    },
    item: {
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    // Fetch advisors, levels, and locations on mount
    const blankResultData = ref({
      discussion: false,
      proposedDecision: 'neither',
      comments: '',
    })

    const resultData = ref({ ...blankResultData.value })

    const resetResultData = () => {
      resultData.value = { ...blankResultData.value }
    }

    // Watch for changes to the sidebar active state
    watch(() => props.isEditResultSidebarActive, (newVal) => {
      if (newVal && props.item) {
        // Populate the form fields when the sidebar is opened
        resultData.value.discussion = props.item.discussion
        resultData.value.proposedDecision = props.item.proposedDecision
        resultData.value.comments = props.item.comments ? props.item.comments : ''

      }
    }, { immediate: true })

    const { proxy } = getCurrentInstance()

    const onSubmit = () => {
      store.dispatch('app-result/editResult', { id: props.item._id, resultData: resultData.value })
        .then(() => {
          emit('update:is-edit-result-sidebar-active', false)
          emit('refetch-data')
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Result Saved Successfully!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch((err) => {
          const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              text: errorMessage,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetResultData)

    return {
      resultData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-result-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
