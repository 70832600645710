import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useResultsList() {
  // Use toast
  const toast = useToast()

  const refResultListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'result', sortable: true },
    // { key: 'level', label: 'Level', sortable: true },
    // { key: 'location', sortable: true },
    // { key: 'advisor', label: 'Advisor', sortable: true },
    { key: 'aggregatedSelfEval', label: 'Self', sortable: true },
    { key: 'aggregated360Eval', label: 'Peer', sortable: true },
    { key: 'aggregatedProjectEval', label: 'End of Project', sortable: true },
    { key: 'total', label: 'Agg. Score', sortable: true },
    { key: 'discussion', sortable: true },
    { key: 'proposedDecision', sortable: true },
    { key: 'comments', sortable: true },

    { key: 'actions' },
  ]
  const limit = ref(10)
  const totalResults = ref(0)
  const page = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('updatedAt')
  const sortOrder = ref('desc')
  const roleFilter = ref(null)
  const levelFilter = ref(null)
  const statusFilter = ref(null)
  const proposedDecisionFilter = ref(null)
  const discussionFilter = ref(null)
  const selectedCycle = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refResultListTable.value ? refResultListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalResults.value,
    }
  })

  const refetchData = () => {
    refResultListTable.value.refresh()
  }

  watch([page, limit, searchQuery, roleFilter, levelFilter, statusFilter, discussionFilter, proposedDecisionFilter], () => {
    refetchData()
  })

  watch([selectedCycle], () => {
    refetchData()

  })


  const fetchResults = (ctx, callback) => {
    store
      .dispatch('app-result/fetchResults', {
        id: selectedCycle.value,
        q: searchQuery.value,
        limit: limit.value,
        page: page.value,
        sortBy: sortBy.value,
        sortOrder: sortOrder.value,
        role: roleFilter.value,
        level: levelFilter.value,
        proposedDecision: proposedDecisionFilter.value,
        discussion: discussionFilter.value,
        isActive: statusFilter.value === 'active' ? true : statusFilter.value === 'inactive' ? false : null,
      })
      .then(response => {
        const { results, total } = response.data
        callback(results)
        totalResults.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching results list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveResultRoleVariant = role => {
    if (role === 'result') return 'primary'
    if (role === 'admin') return 'info'
    return 'primary'
  }

  const resolveResultRoleIcon = role => {
    if (role === 'subscriber') return 'ResultIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'ResultIcon'
  }

  const resolveResultStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveResultDecisionVariant = status => {
    if (status === 'neither') return 'secondary'
    if (status === 'promotion') return 'success'
    if (status === 'warning') return 'danger'
    return 'primary'
  }

  return {
    fetchResults,
    tableColumns,
    limit,
    page,
    totalResults,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    sortOrder,
    refResultListTable,

    resolveResultRoleVariant,
    resolveResultRoleIcon,
    resolveResultStatusVariant,
    resolveResultDecisionVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    levelFilter,
    proposedDecisionFilter,
    discussionFilter,

    selectedCycle,
  }
}
