import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchResults(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        let { id } = queryParams
        if (!id) {
          resolve({ data: { total: 0, results: [] } })
        } else {
          axios
            .get(`/results/${id}`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        }
      })
    },
    fetchResult(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/results/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editResult(ctx, { id, resultData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/results/${id}`, resultData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addResult(ctx, resultData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/signup', resultData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
